<template>
  <div class="main_v8">
    <div class="dlaparea_header">
      <h3>DLAP</h3>
      <div class="report">
        <reportDrop class="report_dropdown" @click="scheduleList = false" :startingId=0 :states=reportArray :enabled=true
                    @selection="reportSelected"></reportDrop>
        <div class="checklist" @click="checkList = true">Select Vehicles</div>
      </div>

      <div class="date_time">

        <div class="date_start">
          <span>Start</span><input type="datetime-local" class="datepicker" v-model="dateStart"/>
        </div>
        <div class="date_end">
          <span>End</span><input type="datetime-local" class="datepicker" v-model="dateEnd"/>
        </div>
      </div>

      <div class="buttons">
        <div>
          <div class="button search_button" @click="getReport">Run Report</div>
          <div class="upload_button" @click="downloadOptions = true"></div>
          <div class="download-options-buttons">
            <div v-if="downloadOptions">
              <a class="button" :href="bdlApiPath + 'dlap/reports/' + downloadName + '.csv'" target="_blank">Download
                CSV</a>
              <a class="button" :href="bdlApiPath + 'dlap/reports/' + downloadName + '.pdf'" target="_blank">Download
                PDF</a>
              <div class="button" @click="downloadOptions = false; emailPDF = true;">Email PDF</div>
              <div class="button secondary" @click="downloadOptions = false">Close</div>
            </div>

            <div class="email_pdf" v-if="emailPDF">
              <label>
                Address<br/>
                <input type="email" v-model="emailPDFAddress"/>
              </label>

              <label>
                Subject<br/>
                <input type="text" v-model="emailPDFSubject"/>
              </label>

              <label>
                Notes<br/>
                <textarea v-model="emailPDFNotes"></textarea>
              </label>
              <div class="buttons">
                <div class="button secondary" @click="emailPDF = false; downloadOptions = true;">Cancel</div>
                <div class="button" @click="sendEmailReport">Send</div>
              </div>
            </div>

          </div>
        </div>
        <div class="button secondary schedule" @click.stop="schedule()">Schedule Reports</div>
        <div v-if="scheduleList && currentlySelectedReportId != 0" class="schedule_list">
          <h3>Saved Reports</h3>
          <p>{{ reportArray[currentlySelectedReportId] }}</p>
          <div class="alarms">
            <transition v-for="subReport of subReports">
              <div class="alarm">
                <p>{{ subReport.instancename }}</p>
                <span @click="reportEditId = subReport.instanceid; setSubReportFormValues(subReport.settings)"></span>
                <span @click="deleteAlarmSubReport = subReport; deleteAlarm = true"></span>
              </div>
            </transition>
          </div>
          <div class="buttons">
            <div class="button secondary" @click.stop="scheduleList = false">cancel</div>
            <div class="button" @click="addSubReport">add</div>
          </div>
        </div>
      </div>

    </div>

    <div class="checklist_window" v-if="checkList">
      <h3>Select Vehicles<span @click="checkList = false"></span></h3>

      <div class="checks">
        <div class="check_container_all">
          <div class="first_row">
            <div :class="checkGroups.mtsAll ? 'checked' : null"
                 @click="checkGroups.mtsAll = !checkGroups.mtsAll; setGroup('mtsAll');">All MTS
            </div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.lclAll ? 'checked' : null"
                 @click="checkGroups.lclAll = !checkGroups.lclAll; setGroup('lclAll');">All LCL
            </div>
          </div>
        </div>

        <div class="check_container">
          <div class="first_row">
            <span>PAE</span>
            <div :class="mts[5] ? 'checked' : null" @click="mts[5] = !mts[5]">MTS06</div>
            <div :class="mts[8] ? 'checked' : null" @click="mts[8] = !mts[8]">MTS09</div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.PAE ? 'checked' : null"
                 @click="checkGroups.PAE = !checkGroups.PAE; setGroup('PAE');">All PAE
            </div>
            <div :class="lcl[5] ? 'checked' : null" @click="lcl[5] = !lcl[5]">LCL06</div>
            <div :class="lcl[7] ? 'checked' : null" @click="lcl[7] = !lcl[7]">LCL08</div>
          </div>
        </div>

        <div class="check_container">
          <div class="first_row">
            <span>IAB</span>
            <div :class="mts[4] ? 'checked' : null" @click="mts[4] = !mts[4]">MTS05</div>
            <div :class="mts[6] ? 'checked' : null" @click="mts[6] = !mts[6]">MTS07</div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.IAB ? 'checked' : null"
                 @click="checkGroups.IAB = !checkGroups.IAB; setGroup('IAB');">All IAB
            </div>
            <div :class="lcl[4] ? 'checked' : null" @click="lcl[4] = !lcl[4]">LCL05</div>
            <div :class="lcl[6] ? 'checked' : null" @click="lcl[6] = !lcl[6]">LCL07</div>
          </div>
        </div>

        <div class="check_container">
          <div class="first_row">
            <span>CHS</span>
            <div :class="mts[7] ? 'checked' : null" @click="mts[7] = !mts[7]">MTS08</div>
            <div :class="mts[9] ? 'checked' : null" @click="mts[9] = !mts[9]">MTS10</div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.CHS ? 'checked' : null"
                 @click="checkGroups.CHS = !checkGroups.CHS; setGroup('CHS');">All CHS
            </div>
            <div :class="lcl[8] ? 'checked' : null" @click="lcl[8] = !lcl[8]">LCL09</div>
            <div :class="lcl[9] ? 'checked' : null" @click="lcl[9] = !lcl[9]">LCL10</div>
          </div>
        </div>

        <div class="check_container">
          <div class="first_row">
            <span>TAR</span>
            <div :class="mts[1] ? 'checked' : null" @click="mts[1] = !mts[1]">MTS02</div>
            <div :class="mts[2] ? 'checked' : null" @click="mts[2] = !mts[2]">MTS03</div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.TAR ? 'checked' : null"
                 @click="checkGroups.TAR = !checkGroups.TAR; setGroup('TAR');">All TAR
            </div>
            <div :class="lcl[1] ? 'checked' : null" @click="lcl[1] = !lcl[1]">LCL02</div>
            <div :class="lcl[3] ? 'checked' : null" @click="lcl[3] = !lcl[3]">LCL04</div>
          </div>
        </div>

        <div class="check_container">
          <div class="first_row">
            <span>NGO</span>
            <div :class="mts[0] ? 'checked' : null" @click="mts[0] = !mts[0]">MTS01</div>
            <div :class="mts[3] ? 'checked' : null" @click="mts[3] = !mts[3]">MTS04</div>
          </div>

          <div class="second_row">
            <div :class="checkGroups.NGO ? 'checked' : null"
                 @click="checkGroups.NGO = !checkGroups.NGO; setGroup('NGO');">All NGO
            </div>
            <div :class="lcl[0] ? 'checked' : null" @click="lcl[0] = !lcl[0]">LCL01</div>
            <div :class="lcl[2] ? 'checked' : null" @click="lcl[2] = !lcl[2]">LCL03</div>
          </div>
        </div>

      </div>

    </div>

    <div class="report_settings" v-if="reportSettings > 0">
      <!-- currentlySelectedReportId == 6  Peak Equipment Pressures -->
      <div v-if="reportSettings === 1">
        <h3>Peak Equipment Pressures</h3>
        <div class="settings">
          <label>
            Report Type<br/>
            <select v-model="reportArrayParams['6'].val.type">
              <option value="Oil Pressure">Oil Pressure</option>
              <option value="Hydraulic Pressure">Hydraulic Pressure</option>
            </select>
          </label>

          <label>
            LCL 1 High Threshold<br/>
            <input type="number" v-model="reportArrayParams['6'].val.lcl1">
          </label>

          <label>
            LCL 2 High Threshold<br/>
            <input type="number" v-model="reportArrayParams['6'].val.lcl2">
          </label>

          <label>
            MTS High Threshold<br/>
            <input type="number" v-model="reportArrayParams['6'].val.mts">
          </label>

          <div class="buttons">
            <div class="button secondary" @click="reportSettings = 0">Cancel</div>
            <div class="button" @click="reportArrayParams['6'].set = true; reportSettings = 0; getReport();">Go</div>
          </div>
        </div>
      </div>

      <!-- currentlySelectedReportId == 10  Alarm Report -->
      <div v-if="reportSettings === 2">
        <h3>Alarm Report</h3>
        <div class="settings">

          <label>
            Threshold<br/>
            <input type="number"  v-model="reportArrayParams['10'].val">
          </label>

          <div class="buttons">
            <div class="button secondary" @click="reportSettings = 0">Cancel</div>
            <div class="button" @click="reportArrayParams['10'].set = true; reportSettings = 0; getReport();">Go</div>
          </div>
        </div>
      </div>

      <!-- currentlySelectedReportId == 11  Peak Equipment Temperatures -->
      <div v-if="reportSettings === 3">
        <h3>Peak Equipment Temperatures</h3>
        <div class="settings">
          <label>
            Report Type<br/>
            <select v-model="reportArrayParams['11'].val.type">
              <option value="Engine Temperature">Engine Temperature</option>
              <option value="Hydraulic Temperature">Hydraulic Temperature</option>
            </select>
          </label>

          <label>
            LCL 1 High Threshold<br/>
            <input type="number" v-model="reportArrayParams['11'].val.lcl1"/>
          </label>

          <label>
            LCL 2 High Threshold<br/>
            <input type="number" v-model="reportArrayParams['11'].val.lcl2"/>
          </label>

          <label>
            MTS High Threshold<br/>
            <input type="number" v-model="reportArrayParams['11'].val.mts"/>
          </label>

          <div class="buttons">
            <div class="button secondary" @click="reportSettings = 0">Cancel</div>
            <div class="button" @click="reportArrayParams['11'].set = true; reportSettings = 0; getReport();">Go</div>
          </div>
        </div>
      </div>

    </div>

    <div class="results">
      <div v-if="cSRId !== '4'" v-html="result"></div>
      <div v-else-if="result">
        <iframe v-if="result !== 'Please wait while we load the data...'" :srcdoc="result" class="data-iframe" onload='(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+50+"px";}(this));'></iframe>
        <div v-else >{{result}}</div>
      </div>
    </div>

    <transition name="slide-right">
      <div class="edit_report" v-if="reportEditId > 0">
        <h3 @click="here()">{{ reportArray[currentlySelectedReportId] }}</h3>
        <form class="fields" action="" @submit.prevent="editReportSubmit">

          <div class="field name_field">
            <p>Name:</p>
            <input name="reporttitle" />
          </div>

          <div class="field email_field">
            <p>Email Report To (multiple emails separate with ","):</p>
            <textarea name="emails" />
          </div>
          
          <div class="field all">
            <p>Units-Quick Select:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="ALL-MTS" value="false" />
                <input type="checkbox" name="ALL-MTS" value="true" />
                <i class="checkbox"></i>
                ALL-MTS
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-LCL" value="false" />
                <input type="checkbox" name="ALL-LCL" value="true" />
                <i class="checkbox"></i>
                ALL-LCL
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-PAE" value="false" />
                <input type="checkbox" name="ALL-PAE" value="true" />
                <i class="checkbox"></i>
                ALL-PAE
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-IAB" value="false" />
                <input type="checkbox" name="ALL-IAB" value="true" />
                <i class="checkbox"></i>
                ALL-IAB
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-CHS" value="false" />
                <input type="checkbox" name="ALL-CHS" value="true" />
                <i class="checkbox"></i>
                ALL-CHS
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-TAR" value="false" />
                <input type="checkbox" name="ALL-TAR" value="true" />
                <i class="checkbox"></i>
                ALL-TAR
              </label>
              <label class="check padded_label">
                <input type="hidden" name="ALL-NGO" value="false" />
                <input type="checkbox" name="ALL-NGO" value="true" />
                <i class="checkbox"></i>
                ALL-NGO
              </label>
            </div>
          </div>

          <div class="field pae">
            <p>Units-PAE:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="PAE-MTS06" value="false" />
                <input type="checkbox" name="PAE-MTS06" value="true" />
                <i class="checkbox"></i>
                PAE-MTS06
              </label>
              <label class="check padded_label">
                <input type="hidden" name="PAE-MTS09" value="false" />
                <input type="checkbox" name="PAE-MTS09" value="true" />
                <i class="checkbox"></i>
                PAE-MTS09
              </label>
              <label class="check padded_label">
                <input type="hidden" name="PAE-LCL06" value="false" />
                <input type="checkbox" name="PAE-LCL06" value="true" />
                <i class="checkbox"></i>
                PAE-LCL06
              </label>
              <label class="check padded_label">
                <input type="hidden" name="PAE-LCL08" value="false" />
                <input type="checkbox" name="PAE-LCL08" value="true" />
                <i class="checkbox"></i>
                PAE-LCL08
              </label>
            </div>
          </div>

          <div class="field iab">
            <p>Units-IAB:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="IAB-MTS05" value="false" />
                <input type="checkbox" name="IAB-MTS05" value="true" />
                <i class="checkbox"></i>
                IAB-MTS05
              </label>
              <label class="check padded_label">
                <input type="hidden" name="IAB-MTS07" value="false" />
                <input type="checkbox" name="IAB-MTS07" value="true" />
                <i class="checkbox"></i>
                IAB-MTS07
              </label>
              <label class="check padded_label">
                <input type="hidden" name="IAB-LCL05" value="false" />
                <input type="checkbox" name="IAB-LCL05" value="true" />
                <i class="checkbox"></i>
                IAB-LCL05
              </label>
              <label class="check padded_label">
                <input type="hidden" name="IAB-LCL07" value="false" />
                <input type="checkbox" name="IAB-LCL07" value="true" />
                <i class="checkbox"></i>
                IAB-LCL07
              </label>
            </div>
          </div>

          <div class="field chs">
            <p>Units-CHS:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="CHS-MTS08" value="false" />
                <input type="checkbox" name="CHS-MTS08" value="true" />
                <i class="checkbox"></i>
                CHS-MTS08
              </label>
              <label class="check padded_label">
                <input type="hidden" name="CHS-MTS10" value="false" />
                <input type="checkbox" name="CHS-MTS10" value="true" />
                <i class="checkbox"></i>
                CHS-MTS10
              </label>
              <label class="check padded_label">
                <input type="hidden" name="CHS-LCL09" value="false" />
                <input type="checkbox" name="CHS-LCL09" value="true" />
                <i class="checkbox"></i>
                CHS-LCL09
              </label>
              <label class="check padded_label">
                <input type="hidden" name="CHS-LCL10" value="false" />
                <input type="checkbox" name="CHS-LCL10" value="true" />
                <i class="checkbox"></i>
                CHS-LCL10
              </label>
            </div>
          </div>

          <div class="field tar">
            <p>Units-TAR:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="TAR-MTS02" value="false" />
                <input type="checkbox" name="TAR-MTS02" value="true" />
                <i class="checkbox"></i>
                TAR-MTS02
              </label>
              <label class="check padded_label">
                <input type="hidden" name="TAR-MTS03" value="false" />
                <input type="checkbox" name="TAR-MTS03" value="true" />
                <i class="checkbox"></i>
                TAR-MTS03
              </label>
              <label class="check padded_label">
                <input type="hidden" name="TAR-LCL02" value="false" />
                <input type="checkbox" name="TAR-LCL02" value="true" />
                <i class="checkbox"></i>
                TAR-LCL02
              </label>
              <label class="check padded_label">
                <input type="hidden" name="TAR-LCL04" value="false" />
                <input type="checkbox" name="TAR-LCL04" value="true" />
                <i class="checkbox"></i>
                TAR-LCL04
              </label>
            </div>
          </div>

          <div class="field ngo">
            <p>Units-NGO:</p>
            <div>
              <label class="check padded_label">
                <input type="hidden" name="NGO-MTS01" value="false" />
                <input type="checkbox" name="NGO-MTS01" value="true" />
                <i class="checkbox"></i>
                NGO-MTS01
              </label>
              <label class="check padded_label">
                <input type="hidden" name="NGO-MTS04" value="false" />
                <input type="checkbox" name="NGO-MTS04" value="true" />
                <i class="checkbox"></i>
                NGO-MTS04
              </label>
              <label class="check padded_label">
                <input type="hidden" name="NGO-LCL01" value="false" />
                <input type="checkbox" name="NGO-LCL01" value="true" />
                <i class="checkbox"></i>
                NGO-LCL01
              </label>
              <label class="check padded_label">
                <input type="hidden" name="NGO-LCL03" value="false" />
                <input type="checkbox" name="NGO-LCL03" value="true" />
                <i class="checkbox"></i>
                NGO-LCL03
              </label>
            </div>
          </div>
          <div class="field other_settings" v-if="reportArray[currentlySelectedReportId] === 'Alarm Report'">
            <p>Other Settings:</p>
            <div>
              <label>
                Count Threshold
                <input name="minimum-otherprm" type="text" value="0"/>
              </label>
            </div>
          </div>

          <div class="field other_settings" v-if="reportArray[currentlySelectedReportId] === 'Peak Equipment Pressures'
                                                || reportArray[currentlySelectedReportId] === 'Peak Equipment Temperatures'">
            <p>Other Settings:</p>
            <div>
              Report Type
              <select v-if="reportArray[currentlySelectedReportId] === 'Peak Equipment Temperatures'" name="type-otherprm">
                <option value="Engine Temperature">Engine Temperature</option>
                <option value="Hydraulic Temperature">Hydraulic Temperature</option>
              </select>
              <select v-if="reportArray[currentlySelectedReportId] === 'Peak Equipment Pressures'" name="type-otherprm">
                <option value="Oil Pressure">Oil Pressure</option>
                <option value="Hydraulic Pressure">Hydraulic Pressure</option>
              </select>
              <label>
                LCL 1 High Threshold
                <input name="lcl1high-otherprm" type="text"/>
              </label>
              <label>
                LCL 2 High Threshold
                <input name="lcl2high-otherprm" type="text"/>
              </label>
              <label>
                MTS High Threshold
                <input name="mtshigh-otherprm" type="text"/>
              </label>
            </div>
          </div>

          <div class="field recurrance_field">
            <p>Recurrance Pattern:</p>
            <select name="period" v-model="recurrence">
              <option disabled :value="''">None</option>
              <option :value="'Daily'">Daily</option>
              <option :value="'Weekly'">Weekly</option>
              <option :value="'Monthly'">Monthly</option>
              <option :value="'Yearly'">Yearly</option>
            </select>
          </div>

          <div class="field report_range">
            <p>Report Start/End:</p>
            <div v-if="recurrence === 'Daily'">
              <label>
                <input type="hidden" name="starttime-hour" v-model="starttimeHour" />
                <input type="hidden" name="starttime-minute" v-model="starttimeMinute" />
                <input type="hidden" name="starttime-ampm" v-model="starttimeAmpm" />
                <span>Start Time</span>
                <input type="time" v-model="starttime" @change="setStarttime" />
              </label>
              <div class="radio duration" :class="reportRange === 1 ? 'radio_active' : null">
                <transition-group v-if="reportRange === 1">
                  <input type="hidden" name="endtime-duration-hours" v-model="endtimeDurationHours" />
                  <input type="hidden" name="endtime-duration-minutes" v-model="endtimeDurationMinutes" />
                </transition-group>
                Duration <input type="time" @click.stop="reportRange = 1" v-model="endtimeDuration" @change="setEndtimeDuration" />
              </div>
              <div class="radio end_time" :class="reportRange === 2 ? 'radio_active' : null">
                <transition-group v-if="reportRange === 2">
                  <input type="hidden" name="endtime-time-hour" v-model="endtimeTimeHour" />
                  <input type="hidden" name="endtime-time-minute" v-model="endtimeTimeMinute" />
                  <input type="hidden" name="endtime-time-ampm" v-model="endtimeTimeAmpm" />
                </transition-group>
                End Time <input type="time" @click.stop="reportRange = 2" v-model="endtimeTime" @change="setEndtimeTime" />
              </div>
            </div>
            <div v-if="recurrence === 'Weekly'">
              <div>Start Date</div>
              <div class="startday_week">
                <label v-for="(day,index) of week" :key="index" :for="index">
                  <input name="startdate-dayofweek" type="radio" :id="index" :value="day" />
                  {{day}}
                  <span class="checkmark"></span>
                </label>
              </div>
              <label>
                <input type="hidden" name="starttime-hour" v-model="starttimeHour" />
                <input type="hidden" name="starttime-minute" v-model="starttimeMinute" />
                <input type="hidden" name="starttime-ampm" v-model="starttimeAmpm" />
                Start Time <input type="time" v-model="starttime" @change="setStarttime" />
              </label>
              <div class="endday_week">
                <label v-for="(day,index) of week" :key="index" :for="index+'end'">
                  <input :id="index+'end'" name="enddate-dayofweek" type="radio" :value="day" />
                  {{day}}
                  <span class="checkmark"></span>
                </label>
              </div>
              <label>
                <input type="hidden" name="endtime-time-hour" v-model="endtimeTimeHour" />
                <input type="hidden" name="endtime-time-minute" v-model="endtimeTimeMinute" />
                <input type="hidden" name="endtime-time-ampm" v-model="endtimeTimeAmpm" />
                End Time <input type="time" v-model="endtimeTime" @change="setEndtimeTime" />
              </label>
            </div>
            <div v-if="recurrence === 'Monthly'">
              <div>
                Start Date


                <div class="radio" @click="monthlyStart=1;startdaychanged=1; startdateweekofmonth=''; startdatedayofweek='';startDayOfMonth=1" :class="monthlyStart === 1 || startDayOfMonth != '' && startdateweekofmonth == '' && monthlyStart !== 2 ? 'radio_active' : null">
                  <input type="hidden" :name="startdaychanged != 1 ? 'startdate-dayofmonth' : null" :value="startDayOfMonth"/>
                  Day of Month <select :name="monthlyStart === 1 ? 'startdate-dayofmonth' : null" @change="monthlyStart = 1; startdaychanged = 1; startdateweekofmonth = ''; startdatedayofweek = '';">
                    <option v-for="day of 31" :key="day" :value="day" :selected="day == startDayOfMonth">{{day}}</option>
                  </select>
                </div>
                <div class="radio" @click="monthlyStart = 2; startDayOfMonth = '';startdaychanged=2; startdateweekofmonth='First';startdatedayofweek='Monday';" style="margin-bottom: 10px" :class="monthlyStart === 2 || monthlyStart !== 1 && startdateweekofmonth != '' ? 'radio_active' : null">
                  <input type="hidden" name="startdate-weekofmonth" :value="startdaychanged == 2 ? '' : startdateweekofmonth" />
                  <div>Week <select :name="monthlyStart === 2 ? 'startdate-weekofmonth' : null" @change="monthlyStart = 2; startdaychanged = 2; startDayOfMonth = '';">
                    <option value="First" :selected="startdateweekofmonth == 'First'" >First</option>
                    <option value="Second" :selected="startdateweekofmonth == 'Second'" >Second</option>
                    <option value="Third" :selected="startdateweekofmonth == 'Third'" >Third</option>
                    <option value="Fourth" :selected="startdateweekofmonth == 'Fourth'" >Fourth</option>
                    <option value="Fifth" :selected="startdateweekofmonth == 'Fifth'" >Fifth</option>
                    </select>
                  </div>
                  <input type="hidden" name="startdate-dayofweek" :value="startdaychanged == 2 ? '' : startdatedayofweek" />
                  <div>Day 
                    <select :name="monthlyStart === 2 ? 'startdate-dayofweek' : null" @change="monthlyStart = 2; startdaychanged = 2; startDayOfMonth = '';">
                      <option v-for="day of week" :key="day" :value="day" :selected="day == startdatedayofweek" >{{day}}</option>
                    </select>
                  </div>
                </div>
                <input type="hidden" name="starttime-hour" v-model="starttimeHour" />
                <input type="hidden" name="starttime-minute" v-model="starttimeMinute" />
                <input type="hidden" name="starttime-ampm" v-model="starttimeAmpm" />
                Start Time <input type="time" v-model="starttime" @change="setStarttime" />
              </div>
              <div style="margin-top: 10px">
                End Date


                <div class="radio" @click="monthlyEnd=1;enddaychanged = 1;enddateweekofmonth = '';enddatedayofweek='';endDayOfMonth=1" :class="monthlyEnd === 1 || endDayOfMonth != '' && enddateweekofmonth == '' && monthlyEnd !== 2? 'radio_active' : null">
                  <input type="hidden"  :name="enddaychanged != 1 ? 'enddate-dayofmonth': null" :value="endDayOfMonth" />
                  Day of Month <select :name="monthlyEnd === 1 ? 'enddate-dayofmonth' : null" @change="monthlyEnd = 1; enddaychanged = 1;">
                    <option v-for="day of 31" :key="day" :value="day" :selected="day == endDayOfMonth">{{day}}</option>
                  </select>
                </div>


                <div class="radio" @click="monthlyEnd=2;endDayOfMonth='';enddaychanged=2;enddateweekofmonth='First';enddatedayofweek='Monday';" style="margin-bottom: 10px" :class="monthlyEnd === 2 || monthlyEnd !== 1 && enddateweekofmonth != '' ? 'radio_active' : null">
                  <input type="hidden" name="enddate-weekofmonth" :value="enddaychanged == 2 ? '' : enddateweekofmonth" />
                  <div>Week <select :name="monthlyEnd === 2 ? 'enddate-weekofmonth' : null" @change="monthlyEnd=2;enddaychanged=2;endDayOfMonth=''">
                    <option value="First" :selected="enddateweekofmonth == 'First'" >First</option>
                    <option value="Second" :selected="enddateweekofmonth == 'Second'" >Second</option>
                    <option value="Third" :selected="enddateweekofmonth == 'Third'" >Third</option>
                    <option value="Fourth" :selected="enddateweekofmonth == 'Fourth'" >Fourth</option>
                    <option value="Fifth" :selected="enddateweekofmonth == 'Fifth'" >Fifth</option>
                    </select>
                  </div>
                  <input type="hidden" name="enddate-dayofweek" :value="enddaychanged == 2 ? '' : enddatedayofweek" />
                  <div>Day 
                    <select :name="monthlyEnd === 2 ? 'enddate-dayofweek' : null" @change="monthlyEnd = 2;enddaychanged=2;endDayOfMonth=''">
                      <option v-for="day of week" :key="day" :value="day" :selected="day == enddatedayofweek" >{{day}}</option>
                    </select>
                  </div>
                </div>
                <input type="hidden" name="endtime-time-hour" v-model="endtimeTimeHour" />
                <input type="hidden" name="endtime-time-minute" v-model="endtimeTimeMinute" />
                <input type="hidden" name="endtime-time-ampm" v-model="endtimeTimeAmpm" />
                End Time <input type="time" v-model="endtimeTime" @change="setEndtimeTime" />
              </div>
            </div>
            <div v-if="recurrence === 'Yearly'">
              <div>
                Start Date
                <div class="radio" :class="yearlyStart === 1 ? 'radio_active' : null">
                  <input type="hidden" name="startdate-month" value="" />
                  <input type="hidden" name="startdate-day" value="" />
                  Month <select :name="yearlyStart === 1 ? 'startdate-month' : null" @click="yearlyStart = 1"><option v-for="month of year" :key="month" :value="month" :selected="month == startdatemonth" >{{month}}</option></select>
                  Day <select :name="yearlyStart === 1 ? 'startdate-day' : null" @click="yearlyStart = 1"><option v-for="day of 31" :key="day" :value="day" :selected="day == startdateday">{{day}}</option></select>
                </div>

                <div class="radio" style="margin-bottom: 10px" :class="yearlyStart === 2 ? 'radio_active' : null">
                  <input type="hidden" name="startdate-weekofmonth" value="" />
                  <div>Week <select :name="yearlyStart === 2 ? 'startdate-weekofmonth' : null" @click="yearlyStart = 2">
                    <option value="First" :selected="startdateweekofmonth == 'First'" >First</option>
                    <option value="Second" :selected="startdateweekofmonth == 'Second'" >Second</option>
                    <option value="Third" :selected="startdateweekofmonth == 'Third'" >Third</option>
                    <option value="Fourth" :selected="startdateweekofmonth == 'Fourth'" >Fourth</option>
                    <option value="Fifth" :selected="startdateweekofmonth == 'Fifth'" >Fifth</option>
                    </select>
                  </div>
                  <input type="hidden" name="startdate-dayofweek" value="" />
                  <input type="hidden" name="startdate-monthofyear" value="" />
                  <div>Day <select :name="yearlyStart === 2 ? 'startdate-dayofweek' : null" @click="yearlyStart = 2"><option v-for="day of week" :key="day" :value="day" :selected="day == startdatedayofweek" >{{day}}</option></select></div>
                  <div>Of <select :name="yearlyStart === 2 ? 'startdate-monthofyear' : null" @click="yearlyStart = 2"><option v-for="month of year" :key="month" :value="month" :selected="month = startdatemonthofyear" >{{month}}</option></select></div>
                </div>
                <input type="hidden" name="starttime-hour" v-model="starttimeHour" />
                <input type="hidden" name="starttime-minute" v-model="starttimeMinute" />
                <input type="hidden" name="starttime-ampm" v-model="starttimeAmpm" />
                Start Time <input type="time" v-model="starttime" @change="setStarttime"/>
              </div>

              <div style="margin-top: 10px">
                End Date
                <div class="radio" :class="yearlyEnd === 1 ? 'radio_active' : null">
                  <input type="hidden" name="enddate-month" value="" />
                  <input type="hidden" name="enddate-day" value="" />
                  Month <select :name="yearlyEnd === 1 ? 'enddate-month' : null" @click="yearlyEnd = 1"><option v-for="month of year" :key="month" :value="month" :selected="month == enddatemonth">{{month}}</option></select>
                  Day<select :name="yearlyEnd === 1 ? 'enddate-day' : null" @click="yearlyEnd = 1"><option v-for="day of 31" :key="day" :value="day" :selected="day == enddateday" >{{day}}</option></select>
                </div>
                <div class="radio" style="margin-bottom: 10px" :class="yearlyEnd === 2 ? 'radio_active' : null">
                  <input type="hidden" name="enddate-weekofmonth" value="" />
                  <div>Week <select :name="yearlyEnd === 1 ? 'enddate-weekofmonth' : null" @click="yearlyEnd = 2">
                    <option value="First" :selected="enddateweekofmonth == 'First'" >First</option>
                    <option value="Second" :selected="enddateweekofmonth == 'Second'" >Second</option>
                    <option value="Third" :selected="enddateweekofmonth == 'Third'" >Third</option>
                    <option value="Fourth" :selected="enddateweekofmonth == 'Fourth'" >Fourth</option>
                    <option value="Fifth" :selected="enddateweekofmonth == 'Fifth'" >Fifth</option>
                    </select>
                  </div>
                  <input type="hidden" name="enddate-dayofweek" value="" />
                  <input type="hidden" name="enddate-monthofyear" value="" />
                  <div>Day <select :name="yearlyEnd === 2 ? 'enddate-dayofweek' : null" @click="yearlyEnd = 2"><option v-for="day of week" :key="day" :value="day" :selected="day == enddatedayofweek" >{{day}}</option></select>
                  <div>Of <select :name="yearlyEnd === 2 ? 'enddate-monthofyear' : null" @click="yearlyEnd = 2"><option v-for="month of year" :key="month" :value="month" :selected="month == enddatemonthofyear">{{month}}</option></select></div>
                  </div>
                </div>
                <input type="hidden" name="endtime-time-hour" v-model="endtimeTimeHour" />
                <input type="hidden" name="endtime-time-minute" v-model="endtimeTimeMinute" />
                <input type="hidden" name="endtime-time-ampm" v-model="endtimeTimeAmpm" />
                End Time <input type="time" v-model="endtimeTime" @change="setEndtimeTime" />
              </div>
            </div>
          </div>

          <div class="field rangeof_occurance">
            <p>Range of Occurance:</p>
            <div>
              Start&nbsp;<input type="date" class="datepicker" name="activestart" />
              End&nbsp;<input type="date" class="datepicker" name="activeend" />
            </div>
            <div v-if="recurrence === 'Daily'">
              <div>
                <div class="radio every_other_day" :class="dailyOccurance === 1 ? 'radio_active' : null">
                  Every
                  <input type="hidden" name="everyxdays" value="" />
                  <select :name="dailyOccurance === 1 ? 'everyxdays' : null" @click.stop="dailyOccurance = 1">
                    <option :value="'1'">1</option>
                    <option :value="'2'">2</option>
                    <option :value="'3'">3</option>
                    <option :value="'4'">4</option>
                    <option :value="'5'">5</option>
                    <option :value="'6'">6</option>
                    <option :value="'7'">7</option>
                  </select>
                  day(s)
                </div>
                <div class="radio days_ofthe_week" :class="dailyOccurance === 2 ? 'radio_active' : null" @click.stop="dailyOccurance = 2">
                  <input type="hidden" name="dayofweek" value="" />
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="0"/>
                    <i class="checkbox"></i>
                    Monday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="1"/>
                    <i class="checkbox"></i>
                    Tuesday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="2"/>
                    <i class="checkbox"></i>
                    Wednesday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="3"/>
                    <i class="checkbox"></i>
                    Thursday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="4"/>
                    <i class="checkbox"></i>
                    Friday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="5"/>
                    <i class="checkbox"></i>
                    Saturday
                  </label>
                  <label class="check padded_label">
                    <input :name="dailyOccurance === 2 ? 'dayofweek' : null" type="checkbox" value="6"/>
                    <i class="checkbox"></i>
                    Sunday
                  </label>
                </div>
              </div>

            </div>
            <div v-if="recurrence === 'Weekly'">
              <label style="text-transform: none; margin-top: 5px">
                Every <select name="everyxweek" style="margin: 0 5px;"> <option v-for="week of 5" :key="week" :value="week" :selected="week == everyxweek" >{{ week }}</option></select> week(s)
              </label>
            </div>
            <div v-if="recurrence === 'Monthly'">
              <label style="text-transform: none; margin-top: 5px">
                Every <select name="everyxmonths" style="margin: 0 5px;"> <option v-for="month of 5" :key="month" :value="month" :selected="month == everyxmonths" >{{ month }}</option></select> month(s)
              </label>
            </div>
          </div>

          <div class="buttons">
            <div class="button secondary" @click.stop="reportEditId = 0; recurrence = ''; backToZero()">cancel</div>
<!--            <div class="button">Submit</div>-->
            <input type="submit" class="button" value="Submit" />
          </div>
        </form>
      </div>
    </transition>

    <div class="delete_anything" v-if="deleteAlarm">
      <h3>Are you sure that you want to delete?</h3>
      <p>{{ deleteAlarmSubReport?.instancename }}</p>
      <div class="buttons">
        <div class="button secondary" @click.stop="deleteAlarmSubReport = null; deleteAlarm = false;">cancel</div>
        <div class="button" @click="deleteSubReport(deleteAlarmSubReport.instanceid); deleteAlarm = false;" >Confirm</div>
      </div>
    </div>

    <div class="context_shadow" v-if="checkList  || deleteAlarm || reportSettings >= 1 || reportEditId > 0" @click="checkList = false; reportEditId = 0; deleteAlarm = false"></div>
  </div>
</template>

<script setup>
import {ref, onMounted, watch, nextTick} from 'vue';
//   import Toggle from '@vueform/toggle';
import '../../src/assets/default.scss';
import reportDrop from '../components/dropdowns/dropdown2.vue';

onMounted(loadReportList);

let reportEditId = ref(0);
let scheduleList = ref(false);
let deleteAlarm = ref(false);
let deleteAlarmSubReport = ref({});
let recurrence = ref("");
let dailyOccurance = ref(0);
let reportRange = ref(0);
let monthlyStart = ref(0);
let monthlyEnd = ref(0);
let yearlyStart = ref(0);
let yearlyEnd = ref(0);
let week = ref(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
let year = ref(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

let starttime = ref("");
let starttimeHour = ref("");
let starttimeMinute = ref("");
let starttimeAmpm = ref("");
let endtimeTime = ref("");
let endtimeTimeHour = ref("");
let endtimeTimeMinute = ref("");
let endtimeTimeAmpm = ref("");
let endtimeDuration = ref("");
let endtimeDurationHours = ref("");
let endtimeDurationMinutes = ref("");
let startDayOfMonth = ref("");
let endDayOfMonth = ref("");

let startdateweekofmonth = ref("");
let startdatedayofweek = ref("");
let enddateweekofmonth = ref("");
let enddatedayofweek = ref("");
let startdatemonthofyear = ref("");
let enddatemonthofyear = ref("");


let startdaychanged = ref(0);
let enddaychanged = ref(0);

let startdatemonth = ref("");
let enddatemonth = ref("");
let startdateday = ref("");
let enddateday = ref("");

let everyxweek = ref("");
let everyxmonths = ref("");


watch(recurrence, () => {
  backToZero();
});
function backToZero() {
  dailyOccurance.value = 0;
  reportRange.value = 0;
  monthlyStart.value = 0;
  monthlyEnd.value = 0;
  yearlyStart.value = 0;
  yearlyEnd.value = 0;
}
function here() {
  console.log(endDayOfMonth.value,'endDayOfMonth')
}

function schedule() {
  if (reportArray.value[currentlySelectedReportId.value] === undefined) {
    alert('Please select a report from the dropdown')
  } else {
    scheduleList.value = true;
  }
}
let result = ref("");
let dateStart = ref(null);
let dateEnd = ref(null);
let checkList = ref(false);

let downloadName = ref('');

let emailPDFAddress = ref('');
let emailPDFSubject = ref('');
let emailPDFNotes = ref('');

let downloadOptions = ref(false);
let emailPDF = ref(false);

let mts = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);

let lcl = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);

let checkGroups = ref({
  mtsAll: false,
  lclAll: false,
  PAE: false,
  IAB: false,
  CHS: false,
  TAR: false,
  NGO: false,
});


let reportSettings = ref(0);

let spinner = ref(false);
let bdlApiPathBase = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
let bdlApiPath = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";

import { inject } from 'vue';
let isarchive = inject('isarchive');
if(isarchive==undefined) isarchive="false";
if(isarchive=="true") {
  bdlApiPath = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
}

let reportArray = ref({});
let reportArrayParams = ref({
  '6': {
    set: false,
    val: {
      type: 'Oil Pressure',
      lcl1: 0,
      lcl2: 0,
      mts: 0,
    },
    formIndex: 1
  },
  '10': {
    set: false,
    val: 0,
    formIndex: 2
  },
  '11': {
    set: false,
    val: {
      type: 'Engine Temperature',
      lcl1: 0,
      lcl2: 0,
      mts: 0,
    },
    formIndex: 3
  },
});
let reportPathsArray = "";
let currentlySelectedReportId = ref("");
let cSRId = ref("");

function loadReportList() {
  const url = bdlApiPathBase + "getReportListv8.php";
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    reportArray.value = response;
    console.log("reportList:", response);
    loadReportPaths();
    spinner.value = false;
  }).catch((error) => {
    console.log("error loading series data:", error);
    spinner.value = false;
  });
}

function loadReportPaths() {
  const url = bdlApiPathBase + "getReportPathsv8.php";
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    reportPathsArray = response;
    //console.log("reportPaths:",response);
    spinner.value = false;
  }).catch((error) => {
    console.log("error loading series data:", error);
    spinner.value = false;
  });
}

function sendEmailReport() {
  if (emailPDFAddress.value === "") {
    alert("Please enter a valid email address");
    return;
  }

  if (emailPDFSubject.value === "") {
    emailPDFSubject.value = "DLAP Report";
  }
  if (emailPDFNotes.value === "") {
    emailPDFNotes.value = "Here is the DLAP Report that you requested";
  }
  let url = "https://boeing.dreamlifter.wave7.co/scripts/notify/ondemandnogen.php?mailto=" + emailPDFAddress.value;
  url += "&subj=" + encodeURIComponent(emailPDFSubject.value);
  url += "&body=" + encodeURIComponent(emailPDFNotes.value);
  url += "&fn=" + encodeURIComponent(downloadName.value);
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'text/html'},
  }).then(() => {
  }).catch(() => {
    console.log('called');
  });
  downloadOptions.value = false;
  emailPDF.value = false;
}

function setGroup(groupName) {
  let mtsList = [];
  let lclList = [];
  switch (groupName) {
    case "mtsAll":
      mts.value.forEach(function (checkbox, index) {
        mts.value[index] = checkGroups.value.mtsAll;
      });
      return;
    case "lclAll":
      lcl.value.forEach(function (checkbox, index) {
        lcl.value[index] = checkGroups.value.lclAll;
      });
      return;
    case "PAE":
      mtsList = [5, 8];
      lclList = [5, 7];
      break;
    case "IAB":
      mtsList = [4, 6];
      lclList = [4, 6];
      break;
    case "CHS":
      mtsList = [7, 9];
      lclList = [8, 9];
      break;
    case "TAR":
      mtsList = [1, 2];
      lclList = [1, 3];
      break;
    case "NGO":
      mtsList = [0, 3];
      lclList = [0, 2];
      break;
  }
  mtsList.forEach(function (index) {
    mts.value[index] = checkGroups.value[groupName];
  });
  lclList.forEach(function (index) {
    lcl.value[index] = checkGroups.value[groupName];
  });
}

let subReports = ref([]);
function reportSelected(id) {
  result.value = '';
  currentlySelectedReportId.value = id;
  cSRId.value = id.toString();
  reportEditId.value = 0;
  console.log(reportEditId.value, 'reportEditId.value');
  console.log(id, 'reportSelected id');

  getSubRepostsList();
}
async function getSubRepostsList() {
  const url = bdlApiPathBase + "getSubReportListv8.php?reportid=" + currentlySelectedReportId.value;
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    if (response !== null && response.length > 0) {
      let results = response;
      results.forEach(function (result, index) {
        results[index].settings = JSON.parse(result.settings);
        results[index].parameters = JSON.parse(result.parameters);
      });
      subReports.value = results;
    } else {
      subReports.value = [];
    }
    return true;
  }).catch((error) => {
    console.log("error loading series data:", error);
    subReports.value = [];
    return false;
  });
}

function addSubReport() {
  const url = bdlApiPathBase + "createNewInstancev8.php?reportid=" + currentlySelectedReportId.value;
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'text/plain'},
  }).then((response) => {
    console.log(response);
    getSubRepostsList();
  }).catch((error) => {
    console.log("error loading series data:", error);
  });
}

function deleteSubReport(id) {
  if (id === undefined || id === null) {
    alert('No subReport id');
    return;
  }
  const url = bdlApiPathBase + "saveSchedulev8.php?instanceid=" + id + "&delete";
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'application/json'},
  }).then((response) => {
    console.log(response);
    getSubRepostsList();
  }).catch((error) => {
    console.log("error loading series data:", error);
  });
}

async function setSubReportFormValues(data) {
  await nextTick();
  console.log(data,'1111111111111111');
  recurrence.value = data.period;
  await nextTick();
  await Object.entries(data).forEach(function (element) {
    let key = element[0];
    let value = element[1];
    let domElement = document.querySelector('[name="' + key + '"]:not([type="hidden"])');
    if (domElement !== null) {
      switch (domElement.tagName) {
        case 'INPUT':
          switch (domElement.getAttribute('type')) {
            case 'checkbox':
              if (value === 'true') {
                domElement.checked = true;
              }
              break;
            case 'radio':
              document.getElementsByName(key).forEach(function (e) {
                if (e.value === value) {
                  e.checked = true;
                }
              });
              break;
            case 'date':
              domElement.value = value;
              break;
            default:
              domElement.value = value;
          }
          break;
        case 'TEXTAREA':
          domElement.value = value;
          break;
        case 'SELECT':
          domElement.value = value;
          break;
        default:
          break;
      }
    } else {
      if (value === '') {
        return;
      }
      switch (key) {
        case 'starttime-hour':
          starttimeHour.value = value;
          break;
        case 'starttime-minute':
          starttimeMinute.value = value;
          break;
        case 'starttime-ampm':
          starttimeAmpm.value = value;
          break;
        case 'endtime-time-hour':
          reportRange.value = 2;
          nextTick().then(()=>{
            endtimeTimeHour.value = value;
          });
          break;
        case 'endtime-time-minute':
          reportRange.value = 2;
          nextTick().then(()=>{
            endtimeTimeMinute.value = value;
          });
          break;
        case 'endtime-time-ampm':
          reportRange.value = 2;
          nextTick().then(()=>{
            endtimeTimeAmpm.value = value;
          });
          break;
        case 'endtime-duration-hours':
          reportRange.value = 1;
          nextTick().then(()=>{
            endtimeDurationHours.value = value;
          });
          break;


          
        case 'everyxweek':
          reportRange.value = 2;
          nextTick().then(()=>{
            everyxweek.value = value;
          });
          break;
        case 'everyxmonths':
          reportRange.value = 1;
          nextTick().then(()=>{
            everyxmonths.value = value;
          });
          break;
        case 'startdate-weekofmonth':
          reportRange.value = 1;
          nextTick().then(()=>{
            startdateweekofmonth.value = value;
          });
          break;
        case 'enddate-weekofmonth':
          reportRange.value = 1;
          nextTick().then(()=>{
            enddateweekofmonth.value = value;
          });
          break;
        case 'startdate-dayofweek':
          reportRange.value = 1;
          nextTick().then(()=>{
            startdatedayofweek.value = value;
          });
          break;
        case 'enddate-dayofweek':
          reportRange.value = 1;
          nextTick().then(()=>{
            enddatedayofweek.value = value;
          });
          break;
        case 'startdate-monthofyear':
          reportRange.value = 1;
          nextTick().then(()=>{
            startdatemonthofyear.value = value;
          });
          break;
        case 'enddate-monthofyear':
          reportRange.value = 1;
          nextTick().then(()=>{
            enddatemonthofyear.value = value;
          });
          break;
        case 'startdate-month':
          reportRange.value = 1;
          nextTick().then(()=>{
            startdatemonth.value = value;
          });
          break;
        case 'enddate-month':
          reportRange.value = 1;
          nextTick().then(()=>{
            enddatemonth.value = value;
          });
          break;
        case 'startdate-day':
          reportRange.value = 1;
          nextTick().then(()=>{
            startdateday.value = value;
          });
          break;
        case 'enddate-day':
          reportRange.value = 1;
          nextTick().then(()=>{
            enddateday.value = value;
          });
          break;
        case 'startdate-dayofmonth':
          reportRange.value = 1;
          nextTick().then(()=>{
            startDayOfMonth.value = value;
          });
          break;
        case 'enddate-dayofmonth':
          reportRange.value = 1;
          nextTick().then(()=>{
            endDayOfMonth.value = value;
          });
          break;
        case 'endtime-duration-minutes':
          reportRange.value = 1;
          nextTick().then(()=>{
            endtimeDurationMinutes.value = value;
          });
          break;
        case 'everyxdays':
          dailyOccurance.value = 1;
          nextTick().then(()=>{
            document.querySelector('[name="everyxdays"]:not([type="hidden"])').value = value;
          });
          break;
        case 'dayofweek':
          dailyOccurance.value = 2;
          nextTick().then(()=>{
            value.forEach((element) => {
              document.querySelector('[name="dayofweek"]:not([type="hidden"])[value="'+ element+'"]').checked = true;
            });
          });
          break;
        default:
          console.log('new fields (key,value): ', key, value);
          break;
      }
    }
  });
  await nextTick();
  starttime.value = composeTime(starttimeHour.value,starttimeMinute.value,starttimeAmpm.value);
  endtimeTime.value = composeTime(endtimeTimeHour.value,endtimeTimeMinute.value,endtimeTimeAmpm.value);
  endtimeDuration.value = endtimeDurationHours.value + ':' + endtimeDurationMinutes.value;
}

async function editSubReport(id, data) {
  let settings = encodeURIComponent(JSON.stringify(data));
  const url = bdlApiPathBase + "saveSchedulev8.php?instanceid="+id+"&settings="+settings;
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    console.log(response);
  }).catch((error) => {
    console.log("error loading series data:", error);
  });
}



const htmlParser = new DOMParser();

function getReport() {
  if (currentlySelectedReportId.value === '') {
    alert('You need to select a report');
    return;
  }
  if (dateStart.value === null || dateEnd.value === null) {
    alert('You need to set start and end dates');
    return;
  }

  if (reportArrayParams.value[currentlySelectedReportId.value] && !reportArrayParams.value[currentlySelectedReportId.value].set) {
    reportSettings.value = reportArrayParams.value[currentlySelectedReportId.value].formIndex;
    return;
  }

  result.value = "Please wait while we load the data...";

  let url = "https://boeing.dreamlifter.wave7.co/";
  if(isarchive=="true") {
    url = "https://archive.boeing.dreamlifter.wave7.co/";
  }
  url += reportPathsArray[currentlySelectedReportId.value];
  url += ".php?minimum=10";
  url += "&start=" + dateToString(dateStart.value);
  url += "&end=" + dateToString(dateEnd.value);
  url += "&mts=" + mts.value.map(value => value ? "1" : "0").join(",");
  url += "&lcl=" + lcl.value.map(value => value ? "1" : "0").join(",");
  url += "&params=";
  switch (currentlySelectedReportId.value) {
    case "6":
      url += JSON.stringify([{
        "title": "Report Title",
        "type": "label",
        "index": 0,
        "width": "250"
      }, {
        "title": "Report Type",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.type
      }, {
        "title": "LCL 1 High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.lcl1.toString()
      }, {
        "title": "LCL 2 High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.lcl2.toString()
      }, {
        "title": "MTS High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.mts.toString()
      }]);
      break;
    case "10":
      url += JSON.stringify([{
        "title": "Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.toString()
      }]);
      break;
    case "11":
      url += JSON.stringify([{
        "title": "Report Title",
        "type": "label",
        "index": 0,
        "width": "250"
      }, {
        "title": "Report Type",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.type
      }, {
        "title": "LCL 1 High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.lcl1.toString()
      }, {
        "title": "LCL 2 High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.lcl2.toString()
      }, {
        "title": "MTS High Threshold",
        "value": reportArrayParams.value[currentlySelectedReportId.value].val.mts.toString()
      }]);
      break;
    default:
      break;
  }

  url = encodeURI(url);
  console.log("GETREPORT-URL", url);
  fetch(url, {
    method: 'Get',
    headers: {'Content-type': 'text/html'},
  }).then((res) => res.text()).then((response) => {
    result.value = response;
    let htmlResponse = htmlParser.parseFromString(response, "text/html");
    downloadName.value = htmlResponse.getElementById('csvdiv').getAttribute('data-csv');
    spinner.value = false;
    if (reportArrayParams.value[currentlySelectedReportId.value]) {
      reportArrayParams.value[currentlySelectedReportId.value].set = false;
    }
  }).catch((error) => {
    console.log("error loading data:", error);
    spinner.value = false;
    if (reportArrayParams.value[currentlySelectedReportId.value] && reportArrayParams.value[currentlySelectedReportId.value].set) {
      reportArrayParams.value[currentlySelectedReportId.value].set = false;
    }
  });
}

async function editReportSubmit(e) {
  let data = formToJsonObject(e.target);
  await editSubReport(reportEditId.value, data);
  console.log('saved data: ', reportEditId.value, data);
  await getSubRepostsList();
  //scheduleList.value = true
  reportEditId.value = 0;
  recurrence.value = '';
  backToZero();
}

function setStarttime() {
  let timeObject = convertTime(starttime.value);
  starttimeHour.value = timeObject.hours.toString();
  starttimeMinute.value = timeObject.minutes.toString();
  starttimeAmpm.value = timeObject.period.toString();
}

function setEndtimeTime() {
  let timeObject = convertTime(endtimeTime.value);
  endtimeTimeHour.value = timeObject.hours.toString();
  endtimeTimeMinute.value = timeObject.minutes.toString();
  endtimeTimeAmpm.value = timeObject.period.toString();
}

function setEndtimeDuration() {
  const [hours, minutes] = endtimeDuration.value.split(':').map(Number);
  endtimeDurationHours.value = hours.toString();
  endtimeDurationMinutes.value = minutes.toString();
}

function convertTime(timeValue) {
  const [hours24, minutes] = timeValue.split(':').map(Number);

  const period = hours24 >= 12 ? 'PM' : 'AM';

  let hours12 = hours24 % 12;

  hours12 = hours12 !== 0 ? hours12 : 12;

  return {'hours': hours12, 'minutes': minutes, 'period': period};
}

function composeTime(hours12, minutes, period) {
  hours12 = parseInt(hours12, 10);
  minutes = parseInt(minutes, 10);

  if (period === 'PM' && hours12 !== 12) {
    hours12 += 12;
  } else if (period === 'AM' && hours12 === 12) {
    hours12 = 0;
  }

  const hours24 = hours12.toString().padStart(2, '0');
  const minutesFormatted = minutes.toString().padStart(2, '0');

  return `${hours24}:${minutesFormatted}`;
}

function dateToString(date) {
  return date.toString().replace('T', ' ') + ':00';
}

function formToJsonObject(formElement) {
  let formData = new FormData(formElement);
  let jsonData = {};

  for (let [key, value] of formData.entries()) {
    if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
      if (Array.isArray(jsonData[key])) {
        jsonData[key].push(value);
      } else {
        let temp = jsonData[key];
        if (temp === 'false' || temp === '') {
          jsonData[key] = value;
        } else {
          jsonData[key] = [temp, value];
        }
      }
    } else {
      jsonData[key] = value;
    }
  }

  return jsonData;
}
</script>

<style lang="scss" scoped>
.main_v8 {
  padding: 0;
  background-color: #fff;
}
.other_settings{
  label{
    flex-direction: column;
    align-items: start !important;
    margin-top: 5px;
  }
}
.startday_week,.endday_week{
  display: flex;
  flex-direction: column;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 50%;
  }
  label{
    padding-left: 32px;
    margin: 5px 0;
  }
  label input:checked ~ .checkmark {
    background-color: #006C3E;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  label input:checked ~ .checkmark:after {
    display: block;
  }
  label .checkmark:after {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
.radio{
  padding: 10px 10px 10px 50px;
  margin-top: 10px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  position: relative;
  transition: all .2s ease;
  cursor: pointer;
  label,input,select{
    cursor: pointer;
  }
}
.radio::after{
  content: '';
  border: 2px solid #787878;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 11px;
  position: absolute;
  box-sizing: border-box;
  left: 15px;
  top: calc(50% - 10px);
}
.radio_active{
  background-color: #e5f5ee;
}
.radio_active::after{
  content: '';
  border: 7px solid #006C3E;
}
.days_ofthe_week{
  display: flex;
  flex-direction: column;
}
.every_other_day{
  display: flex;
  flex-direction: row;
  align-items: center;
  select{
    margin: 0 10px;
  }
}
.edit_report{
  width: 500px;
  position: fixed;
  z-index: 2;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #fff;
  padding: 0 0 110px 30px;
  box-sizing: border-box;
  @media screen and (max-width: 480px) {
    padding: 0 0 80px 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    left: 15px;
    top: 15px;
    bottom: auto;
    right: auto;
    border-radius: 12px;
  }
  .fields{
    overflow-y: auto;
    max-height: calc(100% - 70px);
    .email_field{
      textarea{
        resize: none;
        height: 82px;
        line-height: 1.2rem;
        outline: none;
        box-sizing: border-box;
        width: 100%;
      }
    }
    .field{
      position: relative;
      padding-right: 30px;
      padding-left: 16px;
      color: #787878;
      @media screen and (max-width: 480px) {
        padding-right: 15px;
      }
      p{
        margin: 5px 0 5px -16px;
        font-weight: 500;
        color: #252525;
      }
      select{
        min-width: 50px;
      }
      input:not([type='checkbox']),textarea,select{
        font-size: 1rem;
        border-radius: 8px;
        border: 1px solid #bcbcbc;
        outline: none;
        box-sizing: border-box;
        width: 100%;
      }
      input:not([type='checkbox']),select{
        height: 40px;
        line-height: 38px;
        text-indent: 5px;
      }

      input[type="checkbox"]{
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .padded_label{
        padding-left: 28px;
        @media screen and (max-width: 480px) {
          margin-bottom: 4px;
        }
      }
      label{
        position: relative;
        line-height: 1.23rem;
        font-size: 1rem;
        display: inline-flex !important;
        align-items: center;
        user-select: none;
        border-radius: 8px;
        margin-right: 15px;
        cursor: pointer;
        text-transform: capitalize;
        width: fit-content;
      }
      .checkbox{
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 2px solid #787878;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 9px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
      input[type="checkbox"]:checked+.checkbox {
        background-image: url(../../public/img/check.svg);
        background-color: #787878;
      }
    }
    .field:not(:last-child){
      margin-bottom: 20px;
    }
  }
  .buttons{
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      bottom: 15px;
      left: 15px;
      right: 15px;
    }
    .button{
      width: calc(50% - 8px);
    }
  }
}
.schedule_list{
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(102%);
  width: 390px;
  height: 550px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 2px #0000000F;
  padding: 20px 20px 80px 20px;
  box-sizing: border-box;
  >p{
    margin: 10px 0 15px 0;
  }
  h3,p{
    color: #444;
  }
  .alarms{
    height: calc(100% - 90px);
    overflow-y: auto;
    .alarm:not(:last-child){
      margin-bottom: 10px; 
    }
    .alarm{
      padding: 0 10px 0 10px;
      border: 1px solid #dcdcdc;
      border-radius: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #787878;
      p{
        max-width: calc(100% - 80px);
        color: #787878;
      }
      >span{
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        cursor: pointer;
      }
      >span:nth-child(2){
        background-image: url(../../public/img/edit.svg);
        margin-left: auto;
      }
      >span:nth-child(3){
        background-image: url(../../public/img/delete.svg);
        margin-left: 10px;
      }
    }
  }
  .buttons{
    flex-direction: row !important;
    justify-content: space-between !important;
    position: absolute !important;
    left: 20px;
    right: 20px;
    bottom: 10px;
    @media screen and (max-width: 480px) {
      position: relative !important;
      bottom: -10px;
      left: 0px;
    }
    .button{
      width: calc(50% - 8px);
      justify-content: center !important;
    }
  }
  @media screen and (max-width: 480px) {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: calc(100% - 30px);
    transform: translate(-50%, -50%);
  }
}

.report_settings {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: calc(100% - 30px);
  transform: translate(-50%, -50%);
  width: 450px;
  padding: 20px;
  background-color: #fff;
  z-index: 2;
  border-radius: 12px;
  box-sizing: border-box;

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .settings {
    display: flex;
    flex-direction: column;

    label {
      display: block;
      margin-bottom: 20px;

      input, select {
        height: 40px;
        outline: none;
        width: 50%;
        box-sizing: border-box;
        margin-top: 5px;
        border-radius: 8px;
        border: 1px solid #bcbcbc;
        color: #444;
        font-size: 1rem;
        text-indent: 5px;
        @media screen and (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }

  .buttons {
    .button {
      width: calc(50% - 8px);
    }

    .button:first-child {
      margin-right: 16px;
    }
  }
}

.checklist_window {
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 500px;
  z-index: 2;
  background-color: #fff;
  @media screen and (max-width: 600px) {
    position: fixed;
    left: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 30px);
    border-radius: 15px;
    overflow: hidden;
  }

  h3 {
    height: 70px;
    line-height: 70px;
    margin: 0px;
    padding-left: 20px;
    background-color: #F2F2F2;
    position: relative;

    span {
      position: absolute;
      display: inline-block;
      right: 0px;
      top: 0px;
      height: 70px;
      width: 70px;
      background-image: url(../../public/img/boeing/close.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .checks {
    padding: 30px 50px 20px;
    @media screen and (max-width: 600px) {
      padding: 15px
    }
  }

  .check_container, .check_container_all {
    padding: 0 10px;
    background-color: #7878780D;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;

    .first_row, .second_row {
      span {
        display: block;
      }

      div, span {
        height: 24px;
        line-height: 24px;
        margin: 10px 0;
      }

      div {
        cursor: pointer;
        padding-left: 30px;
        background-image: url(../../public/img/boeing/empty_checkbox.svg);
        background-repeat: no-repeat;
        background-position: center left;
      }

      .checked {
        background-image: url(../../public/img/boeing/checkbox.svg);
      }
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
}

.dlaparea_header {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  padding: 20px 50px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    padding: 20px 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 15px;
  }

  .date_time {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 20px;
    @media screen and (max-width: 800px) {
      margin-right: 0px;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    > div {
      display: flex;
      @media screen and (max-width: 480px) {
        flex-direction: column;
        width: calc(50% - 8px);
      }

      span {
        margin-right: 10px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 480px) {
          height: 24px;
          line-height: 24px;
        }
      }
    }

    > div:first-child {
      margin-bottom: 10px;
      @media screen and (max-width: 480px) {
        margin-bottom: 0px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    @media screen and (max-width: 800px) {
      width: 100%;
      margin-top: 10px;
      justify-content: end;
    }
    .schedule{
      flex-grow: 1;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      text-transform: capitalize;
      position: relative;
    }
  }

  .report {
    padding-left: 80px;
    flex: 1;
    margin: 0 50px;
    position: relative;
    @media screen and (max-width: 980px) {
      margin: 0 20px 0 0;
    }
    @media screen and (max-width: 600px) {
      padding-left: 0px;
    }
    @media screen and (max-width: 480px) {
      margin: 0 0 10px 0;
      width: 100%;
    }

    .report_dropdown {
      position: relative;
      margin-bottom: 10px;
    }

    .checklist {
      height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #787878;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  .report::before {
    content: 'Report';
    position: absolute;
    left: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  h3 {
    margin: 0px;
    @media screen and (max-width: 980px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.results {
  padding: 10px 20px;

  div {
    div {
      table {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  .data-iframe{
    width: 100%;
    border:none;
    overflow:hidden;
  }

  // table{
  //   border-collapse: separate;
  //   width: 100% !important;
  //   max-width: 100% !important;
  //   thead{
  //     th{
  //       height: 44px;
  //       padding: 0px;
  //       border-right: 1px solid #bcbcbc;
  //       background-color: #fff;
  //       span{
  //         font-weight: 400;
  //         font-family: 'Inter', sans-serif;
  //       }
  //     }
  //   }
  //   td{
  //     padding: 10px 0;
  //   }
  //   td:not(:last-child){
  //     border-right: 1px solid #bcbcbc;
  //   }
  //   tr:nth-child(even){
  //     background-color: #7878780D;
  //   }
  //   tr:nth-child(odd){
  //     background-color: #f2f2f2;
  //   }
  // }
}

.context_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.datepicker {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: #252525;
  background-color: #2525251A;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  @media screen and (max-width: 640px) {
    margin-right: 0px;
  }
}

.datepicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: 'Inter', sans-serif;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  font-family: 'Inter', sans-serif;
}

.search_button {
  font-size: 1rem;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-transform: capitalize;
  font-weight: 400;
  // @media screen and (max-width: 1024px) {
  //   background-image: url(../../public/img/boeing/search.svg);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   span{
  //     display: none;
  //   }
  // }
  @media screen and (max-width: 900px) {
    margin-bottom: 5px;
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 0px;
  }
}

.upload_button {
  height: 40px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  background-color: #006C3E;
  background-image: url(../../public/img/boeing/upload.svg);
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 0;
  // @media screen and (max-width: 480px) {
  //   position: fixed;
  //   bottom: auto;
  //   top: 40%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
}

.download-options-buttons {
  position: relative;

  > div {
    position: absolute;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px #0000000F;
    bottom: -20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    transform: translate(-100%, 100%);
    z-index: 1;
    > .button {
      flex-grow: 1;
      padding: 0 20px;
      overflow: hidden;
      white-space: nowrap;
    }

    > .button:not(:last-child) {
      margin-bottom: 20px;
    }

    > a {
      text-decoration: none;
    }
  }

  .email_pdf {
    width: 400px;
    max-width: calc(100vw - 30px);
    @media screen and (max-width: 480px) {
      position: fixed;
      left: 15px;
      top: 200px;
      transform: translate(0);
      width: calc(100% - 30px);
      box-sizing: border-box;
    }

    label {
      display: block;
      margin-bottom: 20px;

      input {
        height: 40px;
      }

      textarea {
        resize: none;
        height: 80px;
      }

      input, textarea {
        outline: none;
        width: 100%;
        box-sizing: border-box;
        margin-top: 5px;
        border-radius: 8px;
        border: 1px solid #bcbcbc;
        color: #444;
        font-size: 1rem;
        text-indent: 5px;
      }
    }

    .buttons {
      width: 100%;

      .button {
        width: calc(50% - 8px);
      }

      .button:first-child {
        margin-right: 16px;
      }
    }
  }
}
  .checklist_window{
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 500px;
    z-index: 2;
    background-color: #fff;
    @media screen and (max-width: 600px){
      position: fixed;
      left: 50%;
      top: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
      max-width: calc(100% - 30px);
      border-radius: 15px;
      overflow: hidden;
    }
    h3{
      height: 70px;
      line-height: 70px;
      margin: 0px;
      padding-left: 20px;
      background-color: #F2F2F2;
      position: relative;
      span{
        position: absolute;
        display: inline-block;
        right: 0px;
        top: 0px;
        height: 70px;
        width: 70px;
        background-image: url(../../public/img/boeing/close.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .checks{
      padding: 30px 50px 20px;
      @media screen and (max-width: 600px){
        padding: 15px
      }
    }
    .check_container,.check_container_all{
      padding: 0 10px;
      background-color: #7878780D;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      justify-content: space-between;
      .first_row,.second_row{
        span{
          display: block;
        }
        div,span{
          height: 24px;
          line-height: 24px;
          margin: 10px 0;
        }
        div{
          cursor: pointer;
          padding-left: 30px;
          background-image: url(../../public/img/boeing/empty_checkbox.svg);
          background-repeat: no-repeat;
          background-position: center left;
        }
        .checked{
          background-image: url(../../public/img/boeing/checkbox.svg);
        }
      }
      @media screen and (max-width: 600px){
        margin-bottom: 10px;
      }
    }
  }
  .dlaparea_header{
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    padding: 20px 50px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1100px){
      padding: 20px 20px;
    }
    @media screen and (max-width: 480px){
      padding: 15px;
    }
    .date_time{
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-right: 20px;
      @media screen and (max-width: 800px){
        margin-right: 0px;
      }
      @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }
      >div{
        display: flex;
        @media screen and (max-width: 480px){
          flex-direction: column;
          width: calc(50% - 8px);
        }
        span{
          margin-right: 10px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 480px){
            height: 24px;
            line-height: 24px;
          }
        }
      }
      >div:first-child{
        margin-bottom: 10px;
        @media screen and (max-width: 480px){
          margin-bottom: 0px;
        }
      }
    }
    .buttons{
      display: flex;
      flex-direction: column;
      >div:first-child{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        @media screen and (max-width: 480px) {
          justify-content: center;
          >div:first-child{
            margin-right: 15px;
          }
        }
      }
      @media screen and (max-width: 800px){
        width: 100%;
        margin-top: 10px;
        justify-content: end;
      }
    }
    .report{
      padding-left: 80px;
      flex: 1;
      margin: 0 50px;
      position: relative;
      @media screen and (max-width: 980px){
        margin: 0 20px 0 0;
      }
      @media screen and (max-width: 600px){
        padding-left: 0px;
      }
      @media screen and (max-width: 480px){
        margin: 0 0 10px 0;
        width: 100%;
      }
      .report_dropdown{
        position: relative;
        margin-bottom: 10px;
      }
      .checklist{
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #787878;
        justify-content: center;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .report::before{
      content: 'Report';
      position: absolute;
      left: 0px;
      height: 40px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px){
        display: none;
      }
    }
    h3{
      margin: 0px;
      @media screen and (max-width: 980px){
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .results{
    padding: 10px 20px;
  }
  .datepicker {
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    position: relative;
    border: none;
    border-radius: 8px;
    outline: 0;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin: 0;
    font-size: 1rem;
    text-align: center;
    color: #252525;
    background-color: #2525251A;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    @media screen and (max-width: 640px) {
      margin-right: 0px;
    }
  }
  .datepicker::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    width: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: 'Inter', sans-serif;
  }
  input[type="date"]::-webkit-datetime-edit-text,
  input[type="date"]::-webkit-datetime-edit-month-field,
  input[type="date"]::-webkit-datetime-edit-day-field,
  input[type="date"]::-webkit-datetime-edit-year-field {
    font-family: 'Inter', sans-serif;
  }

.upload_button, .search_button {
  // margin-left: 10px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  right: -500px;
}
</style>